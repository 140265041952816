@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Aleo:ital@0;1&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap');

*, *::before, *::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

::-webkit-scrollbar {
  display: none;
}


*::-ms-scrollbar {
  display: none;
}

.takeaway::-webkit-scrollbar {
  display: block;
  width: 3px;
  background-color: #2F669D;
  border-radius: 4px;
}
.takeaway-two::-webkit-scrollbar {
  display: block;
  width: 6px;
  background-color: #2F669D;
  border-radius: 4px;
}

.takeaway::-webkit-scrollbar-thumb{
  background-color: #77AADC; /* Color of the thumb */
  border-radius: 4px; /* Border radius of the thumb */
}
.takeaway-two::-webkit-scrollbar-thumb{
  background-color: #77AADC; /* Color of the thumb */
  border-radius: 4px; /* Border radius of the thumb */
}

@layer utilities {
  .outcomes-card {
    @apply bg-bg-1 rounded-lg p-2.5
  }

  .outcomes-heading {
      @apply leading-5 text-sm font-medium
  }

  .outcome-bg {
    @apply bg-bg-1 px-2 py-2.5 rounded-xl flex gap-2 items-start
  }

  .outcome-bg-2{
    @apply bg-bg-1 px-2 py-2.5 rounded-xl flex gap-2 items-center
  }

  .dot-lines {
    @apply ml-[21px] h-4 border-l-2 border-dashed
  }

  .input {
    @apply w-full bg-bg-1 rounded-lg py-3.5 text-white text-sm font-medium placeholder:text-placeholder-text placeholder:font-normal focus:ring-secondary-text focus:border-secondary-text
  }

  .input-2 {
    @apply w-full bg-bg-2 rounded-lg py-2.5 outline-0 border-none focus:border focus:ring-secondary-text
  }

  .heading-2{
    @apply text-xl font-semibold
  }

  .certificate-modal-points {
    @apply flex gap-2 bg-bg-2 p-3 rounded-xl
  }
}


body {
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.first_name:focus, .last_name:focus, .phone_no:focus {
  outline: 1px solid transparent;
  outline-offset: 1px;
  --tw-ring-inset: var(--tw-empty,/*!*/ /*!*/);
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #7FC241;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  border: none;
  border-color: #7FC241;
}

.answer_text:focus {
  outline: 0px solid transparent;
  outline-offset: 0px;
  --tw-ring-inset: var(--tw-empty,/*!*/ /*!*/);
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: none;
  --tw-ring-color: none;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  border: none;
  border-color: #fff;
}

@keyframes down-move {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(8px);
  }
  100% {
    transform: translateY(0);
  }
}

.down {
  animation: down-move 1500ms ease-in-out infinite;
}

@keyframes arrow {
  0% { transform: translateX(0); }
  10%, 30%, 50%, 70%, 90% { transform: translateX(-5px); }
  20%, 40%, 60%, 80% { transform: translateX(5px); }
  100% { transform: translateX(0); }
}

.arrow-move {
  animation: arrow 3s infinite;
}

.btn--glow {
  position:relative;
  overflow:hidden;
}

.btn--glow:before{
  content:'';
  position: absolute;
  left: -20%;
  top:-10%;
  width: 0;
  height: 100%;
  transform: rotate(15deg) scale(1, 2);
  box-shadow: 0 0 30px 15px rgba(255, 255, 255, 0.7);
  animation: button-glow 2000ms ease-in-out infinite;
}

@keyframes button-glow {
  0% {
    left: -20%;
  }
  50% {
    left: 120%;
  }
  100% {
    left: 120%;
  }
}

.user_course:focus {
  outline: 1px solid #7FC241;
  outline-offset: 1px;
  --tw-ring-inset: var(--tw-empty,/*!*/ /*!*/);
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #7FC241;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: 0px 0px 7px 0px #7FC241;
  border: 1px;
  border-color: #7FC241;
}

.skill_search:focus {
  outline: none;
  border: none;
  /* outline-offset: 1px; */
  --tw-ring-inset: none;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  /* --tw-ring-color: #7FC241; */
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  /* box-shadow: 0px 0px 7px 0px #7FC241; */
  /* border: 1px; */
  /* border-color: #7FC241; */
}

.job-role:focus {
  outline: none;
  border: none;
  /* outline-offset: 1px; */
  --tw-ring-inset: none;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  /* --tw-ring-color: #7FC241; */
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  /* box-shadow: 0px 0px 7px 0px #7FC241; */
  /* border: 1px; */
  /* border-color: #7FC241; */
}

.show_shadow {
  outline: 1px solid #7FC241;
  outline-offset: 1px;
  border: 1px;
  border-color: #7FC241;
  box-shadow: 0px 0px 7px 0px #7FC241;
}

.leaderboard::-webkit-scrollbar {
  display: block;
  margin-left: 2px;
  width: 5px;
  background-color: #2F669D;
}

.leaderboard::-webkit-scrollbar-thumb{
  margin-left: 2px;
  background-color: #77AADC; /* Color of the thumb */
  border-radius: 2px; /* Border radius of the thumb */
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus {
  font-size: 1rem !important;
  border: 1.4px solid #7FC241;
  -webkit-text-fill-color: #fff;
  -webkit-box-shadow: 0 0 0px 40rem #2D4F71 inset;
}

@keyframes decrease {
  0% {
    scale: 1;
  }
  50%{
    scale: 0.7;
  }
  100%{
    scale: 1;
  }
}

.blink {
  animation: decrease 1500ms ease-in-out infinite;
}

.glow{
  animation: glowAnimation 300ms infinite alternate;
}

@keyframes glowAnimation {
  0% {
      box-shadow: 0px 0px 8px 2px rgba(127, 194, 65, 0.30);;
  }
  100% {
      box-shadow: 0px 0px 8px 3px rgba(127, 194, 65, 0.40);;
  }
}

.glow-2{
  animation: glowAnimation-2 300ms infinite alternate;
}

@keyframes glowAnimation-2 {
  0% {
      box-shadow: 0px 0px 8px 2px rgba(127, 194, 65, 0.30);
  }
  100% {
      box-shadow: 0px 0px 6px 8px rgba(127, 194, 65, 0.25);
  }
}

.glow-3{
  animation: glowAnimation-3 500ms infinite alternate;
}

@keyframes glowAnimation-3 {
  0% {
      box-shadow: 0px 0px 2px 2px rgba(127, 194, 65, 0.30);
  }
  100% {
      box-shadow: 0px 0px 3px 4px rgba(127, 194, 65, 0.25);
  }
}

.slick-dots li.slick-active button:before {
  color:#238BF2 !important;
}

.slick-dots li button:before {
  width: 20px;
  height: 20px;
  color:#2676C5 !important;
}

.grounded-radiants {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 16px;
  background: linear-gradient(180deg, #CFE4F3 0%, rgba(255, 255, 255, 0) 129.17%);
  background-clip: padding-box;
  padding: 10px;
}

.inside-div {
  position: absolute;
  top: 1.5px; bottom: 1.5px;
  left: 1.5px; right: 1.5px;
  content: '';
  z-index: 1;
  border-radius: 16px;
}

.inside-div {
  position: absolute;
  top: 1.5px; bottom: 1.5px;
  left: 1.5px; right: 1.5px;
  content: '';
  z-index: 1;
  border-radius: 16px;
}

.inline-text {
  display: inline;
}

.blink{
  animation: blink 500ms infinite alternate;
}

@keyframes blink {
  0%, 100% {
    opacity: 1;
    scale: 1;
  }
  25% {
    opacity: 0.75;
    scale: 1.3;
  }
  50% {
    opacity: 0.5;
    scale: 1.6;
  }
  75% {
    opacity: 0.75;
    scale: 1.3;
  }
}

.image-inline{
  display: inline;
}

.pulse-loader {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.pulse-loader-2 {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.pulse-loader div {
  width: 4px;
  height: 4px;
  margin: 2px;
  border-radius: 50%;
  animation: pulse 0.3s infinite alternate;
}

.pulse-loader div:nth-child(2) {
  animation-delay: 0.1s;
}

.pulse-loader div:nth-child(3) {
  animation-delay: 0.2s;
}

.pulse-loader-2 div {
  width: 2px;
  height: 2px;
  margin: 1px;
  border-radius: 50%;
  animation: pulse-2 0.3s infinite alternate;
}

.pulse-loader-2 div:nth-child(2) {
  animation-delay: 0.1s;
}

.pulse-loader-2 div:nth-child(3) {
  animation-delay: 0.2s;
}

@keyframes pulse-2 {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(1.5);
    opacity: 0.5;
  }
}
@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(1.5);
    opacity: 0.5;
  }
}

.swiper-pagination-fraction, .swiper-pagination-custom, .swiper-horizontal > .swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: -25px !important; /* Move it down below the cards */
}

.swiper-pagination-bullet {
  background-color: rgba(255, 255, 255, 0.3) !important;
}

.swiper-pagination-bullet-active {
  background-color: #1983D0 !important;
}

.scrollable-div::-webkit-scrollbar {
  width: 5px; /* Set the width of the scrollbar */
  background-color: #F5F5F5; /* Set the background color of the scrollbar */
  position: absolute;
  right: 200px; /* Position the scrollbar on the left of the element */
}

.scrollable-div::-webkit-scrollbar-thumb {
  background-color:  #AFAFAF;
}

::-webkit-scrollbar-track {
  background: #ECECEC;
  border-radius: 30px;
}





